<script setup>
import BaseCard from "@/components/BaseCard.vue";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import QuestionOppositionSelection from "@/components/imported-questions/QuestionOppositionSelection.vue";
import { computed, ref } from "vue";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import BaseInputDocument from "@/components/BaseInputDocument.vue";
import MainRepository from "@/repositories/MainRepository.js";
import { useForm } from "vee-validate";
import ButtonTertiary from "@/components/buttons/ButtonTertiary.vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseLoading from "@/components/BaseLoading.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";

const { sendSuccessNotification, sendServerError } = useToast();

const oppositionsData = ref([]);
const feedback = ref([]);
const logErrors = ref([]);
const correctQuestions = ref(0);
const documentInput = ref(null);

const hasCheckedDocument = computed(() => {
  return feedback.value.length > 0 || logErrors.value.length > 0;
});

const { values, defineComponentBinds, setErrors, errorBag, setFieldValue, resetForm, isSubmitting, handleSubmit } =
  useForm();

const file = defineComponentBinds("file");

function resetImport() {
  oppositionsData.value = [];
  questionTypeSelected.value = "";
  questionSubtypeSelected.value = "";
  examCategorySelected.value = "";
  resetDocumentUpload();
}

function resetDocumentUpload() {
  feedback.value = [];
  logErrors.value = [];
  correctQuestions.value = 0;
  setErrors({});
  resetForm();
  documentInput.value.reset();
}

const questionTypeOptions = [
  { label: "Banco de preguntas: Preguntas estándar", value: "standard" },
  { label: "Banco de preguntas: Preguntas de repaso (Alto nivel)", value: "review" },
  { label: "Test oficiales", value: "previousCall" },
  { label: "Supuestos prácticos", value: "alleged" },
  { label: "Supuestos prácticos oficiales", value: "allegedPreviousCall" },
];
const questionTypeSelected = ref("");

const questionSubTypeOptions = [
  { label: "Parte general", value: "general" },
  { label: "Parte procesal", value: "procesal" },
];

const questionSubtypeSelected = ref("");

const examCategoryOptions = [
  { label: "Ninguna", value: "" },
  { label: "Parte general", value: "parte general" },
  { label: "Orden civíl", value: "orden civil" },
  { label: "Orden penal", value: "orden penal" },
  { label: "Orden administrativo", value: "orden administrativo" },
  { label: "Orden social", value: "orden social" },
];

const examCategorySelected = ref("");

const step = computed(() => {
  if (oppositionsData.value.length === 0) {
    return 1;
  }
  if (questionTypeSelected.value === "") {
    return 2;
  }
  if (isReviewSelected.value) {
    if (questionSubtypeSelected.value === "") {
      return 2;
    }
  }
  if (isAllegedExamSelected.value) {
    if (examCategorySelected.value === "") {
      return 3;
    }
  }
  return 3;
});

const isReviewSelected = computed(() => {
  return questionTypeSelected.value === "review";
});

const isEmptyExam = computed(() => {
  return correctQuestions.value === 0;
});

const isAllegedExamSelected = computed(() => {
  return questionTypeSelected.value === "alleged" || questionTypeSelected.value === "allegedPreviousCall";
});

async function checkImportDocument() {
  try {
    let response;
    if (questionTypeSelected.value === "standard" || questionTypeSelected.value === "review") {
      response = await MainRepository.checkImportQuestions(values.file);
    } else if (
      questionTypeSelected.value === "previousCall" ||
      questionTypeSelected.value === "alleged" ||
      questionTypeSelected.value === "allegedPreviousCall"
    ) {
      response = await MainRepository.checkImportExam(values.file, questionTypeSelected.value);
    }
    feedback.value = response.data.log;
    logErrors.value = response.data.errors;
    correctQuestions.value = response.data.correctQuestions;
  } catch (e) {
    console.log(e);
    setErrors(e.response.data);
  }
}

const importDocument = handleSubmit(async (values) => {
  if (step.value < 3) {
    return;
  }
  feedback.value = [];
  setErrors({});

  try {
    if (questionTypeSelected.value === "standard" || questionTypeSelected.value === "review") {
      await importQuestions();
    } else if (
      questionTypeSelected.value === "previousCall" ||
      questionTypeSelected.value === "alleged" ||
      questionTypeSelected.value === "allegedPreviousCall"
    ) {
      await importExam();
    }
  } catch (e) {
    sendServerError(e);
  }
});

async function importQuestions() {
  let subtype = ""; // standard questions
  if (questionTypeSelected.value === "review") {
    if (questionSubtypeSelected.value === "") {
      return;
    }
    subtype = questionSubtypeSelected.value;
  }
  let response = await MainRepository.importQuestions(values.file, oppositionsData.value, subtype);
  feedback.value = response.data.log;
  logErrors.value = response.data.errors;
  sendSuccessNotification(
    "Se han importado las preguntas correctamente",
    "Se podrán consultar en el banco de preguntas"
  );
  resetDocumentUpload();
}

async function importExam() {
  let category = "";
  if (questionTypeSelected.value === "alleged" || questionTypeSelected.value === "allegedPreviousCall") {
    category = examCategorySelected.value;
  }
  let response = await MainRepository.importExam(
    values.file,
    oppositionsData.value,
    category,
    questionTypeSelected.value
  );
  feedback.value = response.data.log;
  logErrors.value = response.data.errors;
  sendSuccessNotification(
    "Se han importado el examen con las preguntas correctamente",
    "Se podrán consultar en el apartado de exámenes correspondiente"
  );
  resetDocumentUpload();
}
</script>

<template>
  <title-header-view>
    <template v-slot:header>Importar pregunta de un documento</template>
    <base-card>
      <form class="mb-5 flex flex-col gap-5 p-5">
        <div>
          <h3 class="mb-2"><b>1. Oposiciones</b></h3>
          <question-opposition-selection class="mx-3" v-model="oppositionsData" :select-themes="false" />
        </div>

        <div v-if="step > 1" class="flex flex-col gap-3">
          <h3 class="mb-2"><b>2. ¿Dónde quieres guardar guardar las preguntas del documento?</b></h3>
          <base-select
            v-model="questionTypeSelected"
            :options="questionTypeOptions"
            class="mx-3"
            required
            show-blank-option
            :emit-empty-as-null="false"
            @change="resetDocumentUpload"
          />
          <base-select
            v-if="isReviewSelected"
            v-model="questionSubtypeSelected"
            :options="questionSubTypeOptions"
            :required="isReviewSelected"
            class="mx-3"
            show-blank-option
            :emit-empty-as-null="false"
          />
          <base-select
            v-if="isAllegedExamSelected"
            v-model="examCategorySelected"
            :options="examCategoryOptions"
            class="mx-3"
            :emit-empty-as-null="false"
          />
        </div>

        <div v-if="step > 2">
          <h3 class="mb-2"><b>3. Selecciona documento para importar preguntas</b></h3>
          <base-input-document
            v-bind="file"
            ref="documentInput"
            name="document"
            class="mx-3"
            :required="true"
            :error="errorBag.file"
            @change="setFieldValue('file', $event)"
            :disabled="hasCheckedDocument"
          />
        </div>

        <div v-if="step > 2 && !hasCheckedDocument" class="mx-auto flex flex-row gap-3">
          <button-primary type="submit" class="mx-auto" :disabled="isSubmitting" @click.prevent="checkImportDocument">
            Comprobar preguntas en documento
          </button-primary>
          <button-tertiary @click.prevent="resetImport">Empezar de nuevo</button-tertiary>
        </div>

        <div v-if="feedback.length > 0" class="mx-5">
          <p class="text-xl text-secondary-800" v-for="item in feedback" :key="item" v-html="item"></p>
        </div>
        <div v-if="logErrors.length > 0" class="mx-5">
          <p class="text-lg text-red-200" v-for="item in logErrors" :key="item" v-html="item"></p>
        </div>

        <p v-if="hasCheckedDocument && !isEmptyExam" class="mx-5 mb-3 text-lg text-secondary-800">
          Se van a importar todas las preguntas que no contengan errores. Después de importarlas no se podrá volver a
          importar el mismo documento, por lo que aquellas preguntas que contengan errores tendrán que ser creadas
          manualmente.
        </p>
        <p v-if="isEmptyExam" class="mx-5 mb-3 text-lg text-secondary-800">
          No se encontró ninguna pregunta para importar en el documento. Revise el formato del documento o pruebe con
          otro documento que tenga el formato correcto.
        </p>

        <div v-if="step > 2 && hasCheckedDocument" class="mx-auto flex flex-row gap-3">
          <button-primary type="submit" class="mx-auto" @click="importDocument" :disabled="isSubmitting || isEmptyExam">
            Importar preguntas
          </button-primary>
          <button-tertiary type="button" class="mx-auto" @click="resetDocumentUpload" :disabled="isSubmitting">
            Comprobar otro documento
          </button-tertiary>
          <button-tertiary @click.prevent="resetImport">Empezar de nuevo</button-tertiary>
        </div>

        <div v-if="isSubmitting" class="mx-auto flex flex-row items-center">
          <base-loading />
          <small class="text-center text-base">
            Importando preguntas, esto puede demorarse algún tiempo, por favor espere...
          </small>
        </div>
      </form>
    </base-card>
  </title-header-view>
</template>

<style scoped></style>
